import * as React from 'react'

import { useQuery } from '@apollo/client'

import { Loading, OnboardingForm, SeoHeaders } from 'shared/components'
import { ACCOUNT_INFORMATION_QUERY } from 'shared/queries'
import { isLoggedIn } from 'shared/services'

import Layout from '../layouts/onboarding'

import type { AccountInformationData, AccountInformationVars } from 'shared/queries'

const SITE_NAME = process.env.GATSBY_SITE_NAME

const EDITABLE_STATUSES = [
  'IDENTITY_UNVERIFIED',
  'IDENTITY_PENDING_CHANGES',
]

const Onboarding = () => {
  const { loading, data } = useQuery<AccountInformationData, AccountInformationVars>(ACCOUNT_INFORMATION_QUERY)

  if (loading) {
    return <Loading />
  }

  const accountStatus = data?.accountInformation.accountStatus

  if (!accountStatus || !EDITABLE_STATUSES.includes(accountStatus)) {
    if (isLoggedIn()) {
      window.location.href = '/app/'
    } else {
      window.location.href = '/auth/login/'
    }
    return null
  }

  return (
    <React.Fragment>
      <SeoHeaders
        title='Verificación de identidad'
        description={`Verifica tu identidad para utilizar todas las funciones de ${SITE_NAME}`}
      />
      <Layout>
        <OnboardingForm />
      </Layout>
    </React.Fragment>
  )
}

export default Onboarding
